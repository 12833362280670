var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"titulo"},[_vm._v(" Despacho ")]),_c('div',{staticClass:"box-table"},[void 0,_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{ref:"dTable",staticClass:"mt-4",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.despachosOrdenados,"width":"300px","flat":"","expanded":_vm.expanded,"hide-default-footer":"","loading":_vm.loadingPag,"show-expand":"","items-per-page":_vm.itemsPerPage},on:{"update:expanded":function($event){_vm.expanded=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"item-expanded":_vm.getTransactionExpanded},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BrToolBar',{attrs:{"configFilter":{ 
              listaSelect: _vm.item, 
              getItens: _vm.getItems, 
              jsonData: false, 
              disableTextField: true 
            },"addlFilter":true},on:{"search":_vm.buscar},scopedSlots:_vm._u([{key:"btnSelect",fn:function(){return [_c('v-row',{staticClass:"justify-end"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###########', '(##) # ####-####']),expression:"['###########', '(##) # ####-####']"}],attrs:{"append-icon":"search","label":'Telefone ou Cod. Rev.',"single-line":"","hide-details":"","color":"primary"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscarPorEntidade.apply(null, arguments)}},model:{value:(_vm.textSearch),callback:function ($$v) {_vm.textSearch=$$v},expression:"textSearch"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{staticClass:"select-status",attrs:{"items":_vm.typesTransactions,"label":"Tipo de transação","dense":"","hide-details":"","solo":"","clearable":""},model:{value:(_vm.typeTransaction),callback:function ($$v) {_vm.typeTransaction=$$v},expression:"typeTransaction"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{staticClass:"select-status",attrs:{"items":_vm.transacaoStatus,"label":"Status do PIX","multiple":"","dense":"","hide-details":"","solo":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.status.length > 0 ? _vm.variables.colorPrimary : _vm.variables.colorPrimary}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.status.length - 1)+" outros) ")]):_vm._e()]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('FiltroRangeDate',{staticClass:"mr-3 range-date",staticStyle:{"width":"100%"},attrs:{"value":_vm.rangeDate,"labelBtn":'Selecionar Data',"actionLabels":{ apply: 'OK' },"disableBtnClear":false},on:{"input":_vm.filtrar}})],1)],1)]},proxy:true}])})]},proxy:true},{key:"item.entity.cod",fn:function(ref){
            var item = ref.item;
return [_c('TooltipDadosPixWeb',{key:item.id,attrs:{"item":item,"id":item.id}})]}},{key:"item.entity.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity.jsonData.fullName)+" ")]}},{key:"item.entity.pix",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity.jsonData.pix.keyCode)+" ")]}},{key:"item.type",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.type.name)+" ")]}},{key:"item.transactions",fn:function(ref){
            var item = ref.item;
return [_c('TooltipDadosPixRevenda',{key:item.transactions[item.transactions.length - 1].id,attrs:{"item":item.transactions[item.transactions.length - 1],"id":item.transactions[item.transactions.length - 1].id}})]}},{key:"item.pendingOperation",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValue(item))+" ")]}},{key:"item.created",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.created)+" ")]}},{key:"item.statusCor",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorStatusTransactions(item.transactions)}})]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var dispatch = ref.item;
return [_c('td',{staticClass:"table-expend ",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headersTransaction,"items":_vm.transacoesOrdernadas(dispatch.transactions),"width":"300px","flat":"","hide-default-footer":"","loading":_vm.loadingTransaction,"items-per-page":dispatch.transactions.length},on:{"update:itemsPerPage":function($event){return _vm.$set(dispatch.transactions, "length", $event)},"update:items-per-page":function($event){return _vm.$set(dispatch.transactions, "length", $event)}},scopedSlots:_vm._u([{key:"item.entity.cod",fn:function(ref){
            var item = ref.item;
return [_c('TooltipDadosPixRevenda',{key:item.id,attrs:{"item":item,"id":item.id}})]}},{key:"item.entity.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity.jsonData.name)+" ")]}},{key:"item.type",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.type.name)+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.name)+" ")]}},{key:"item.value",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")]}},{key:"item.updated",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.updated))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"color":_vm.variables.colorGreenDarken,"dark":""},on:{"click":function($event){return _vm.openModalConfirmationPayment(item, dispatch.entity)}}},[_vm._v(" Visualizar ")])]}}],null,true)})],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],2),_c('modalConfirmationPayment',{attrs:{"isActiveModal":_vm.isActiveModalConfirmationPayment,"transactionProp":_vm.dataConfirmationPayment,"entityWeb":_vm.dataEntityWeb},on:{"closeModal":function($event){return _vm.closeModalConfirmationPayment()},"registroConfirmado":function($event){return _vm.closeModalRegistroConfirmado()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }