<template>
  <div class="cadastro-usuario">
    <v-container fluid>

      <h1 class="titulo"> Despacho </h1>
      
      <div class="box-table">

        <template>
          <!-- <filtroBase v-on:buscar="buscar" :historico="getHistorico" :loading="loadingTable" /> -->
        </template>

        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            ref="dTable"
            class="mt-4"
            :headers="headers"
            :search="search"
            :items="despachosOrdenados"
            width="300px"
            flat
            :expanded.sync="expanded"
            v-model="selected"
            hide-default-footer
            :loading="loadingPag"
            show-expand
            :items-per-page.sync="itemsPerPage"
            @item-expanded="getTransactionExpanded"
          >
            <template v-slot:top>
              <BrToolBar @search="buscar" 
              :configFilter="{ 
                listaSelect: item, 
                getItens: getItems, 
                jsonData: false, 
                disableTextField: true 
              }" 
              :addlFilter="true">
                <template v-slot:btnSelect>
                  <v-row class="justify-end">
                    <v-col cols="12" md="3">
                      <v-text-field
                        append-icon="search"
                        :label="'Telefone ou Cod. Rev.'"
                        single-line
                        hide-details
                        v-on:keyup.enter="buscarPorEntidade"
                        v-model="textSearch"
                        v-mask="['###########', '(##) # ####-####']"
                        color="primary" />
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        v-model="typeTransaction"
                        :items="typesTransactions"
                        label="Tipo de transação"
                        dense
                        hide-details
                        solo
                        class="select-status"
                        clearable
                      >
                      </v-select>
                    </v-col>

                    <v-col cols="12" md="3">
                      <!-- <v-select 
                        v-model="status"
                        :items="transacaoStatus"
                        dense
                        hide-details
                        solo
                        class="select-status"
                      /> -->
                      

                      <v-select
                        v-model="status"
                        :items="transacaoStatus"
                        label="Status do PIX"
                        multiple
                        dense
                        hide-details
                        solo
                        class="select-status"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggle"
                          >
                            <v-list-item-action>
                              <v-icon :color="status.length > 0 ? variables.colorPrimary : variables.colorPrimary">
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Todos
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <template v-slot:selection="{ item, index }">
                          <span v-if="index === 0" class="mr-1">{{ item.text }}</span>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ status.length - 1 }} outros)
                          </span>
                        </template>
                      </v-select>


                    </v-col>

                    <v-col cols="12" md="3">
                      <FiltroRangeDate
                        class="mr-3 range-date"
                        @input="filtrar"
                        :value="rangeDate"
                        :labelBtn="'Selecionar Data'"
                        :actionLabels="{ apply: 'OK' }"
                        style="width: 100%;"
                        :disableBtnClear="false"
                      />
                    </v-col>
                  </v-row>
                </template>
              </BrToolBar>
            </template>

            <template v-slot:item.entity.cod="{ item }">
              <TooltipDadosPixWeb :item="item" :key="item.id" :id="item.id" />
            </template>

            <template v-slot:item.entity.name="{ item }">
              {{ item.entity.jsonData.fullName }}
            </template>

            <template v-slot:item.entity.pix="{ item }">
              {{ item.entity.jsonData.pix.keyCode }}
            </template>

            <template v-slot:item.type="{ item }">
              {{ item.type.name }}
            </template>

            <template v-slot:item.transactions="{ item }">
              <TooltipDadosPixRevenda :item="item.transactions[item.transactions.length - 1]" :key="item.transactions[item.transactions.length - 1].id" :id="item.transactions[item.transactions.length - 1].id" />
            </template>

            <template v-slot:item.pendingOperation="{ item }">
              {{ getValue(item) }}
            </template>

            <template v-slot:item.created="{ item }">
              {{ item.created }}
            </template>

            <template v-slot:item.statusCor="{ item }">
              <v-chip
                :color="getColorStatusTransactions(item.transactions)"
              ></v-chip>
            </template>
        

            <template v-slot:expanded-item="{ headers, item: dispatch }">
              <td :colspan="headers.length" class="table-expend ">
                <!-- <v-col class="d-flex justify-center align-center" style="min-height: 150px; width: 100%;" v-if="loadingExpanded">
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col> -->
                
                 <v-data-table
                    class="mt-4"
                    :headers="headersTransaction"
                    :items="transacoesOrdernadas(dispatch.transactions)"
                    width="300px"
                    flat
                    hide-default-footer
                    :loading="loadingTransaction"
                    :items-per-page.sync="dispatch.transactions.length"
                  >

                  <template v-slot:item.entity.cod="{ item }">
                    <TooltipDadosPixRevenda :item="item" :key="item.id" :id="item.id" />
                  </template>

                  <template v-slot:item.entity.name="{ item }">
                    {{ item.entity.jsonData.name }}
                  </template>

                  <template v-slot:item.type="{ item }">
                    {{ item.type.name }}
                  </template>

                  <template v-slot:item.status="{ item }">
                    {{ item.status.name }}
                  </template>

                   <template v-slot:item.value="{ item }">
                    {{ item.value | currency }}
                  </template>

                   <template v-slot:item.updated="{ item }">
                    {{ item.updated | datas }}
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      :color="variables.colorGreenDarken" 
                      @click="openModalConfirmationPayment(item, dispatch.entity)"
                      dark
                    >
                      Visualizar
                    </v-btn>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <modalConfirmationPayment
        :isActiveModal="isActiveModalConfirmationPayment"
        :transactionProp="dataConfirmationPayment" 
        :entityWeb="dataEntityWeb" 
        @closeModal="closeModalConfirmationPayment()"
        @registroConfirmado="closeModalRegistroConfirmado()"
      />
    </v-container>
  </div>
</template>

<script>
// eslint-disable-next-line
import Events from '@/core/service/events'
import listagemTables from '@/views/mixins/listagemTables'
// eslint-disable-next-line
import { map, flatten, orderBy } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar, currency } from '@/core/service/utils'
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import moment from 'moment'
import { mask } from 'vue-the-mask'

export default {
  name: 'Despacho',
  components: {
    BrToolBar: () => import('./components/toolbar'),
    modalConfirmationPayment: () => import('./components/modal'),
    FiltroRangeDate: () => import('@/views/components/filtroRangeDate'),
    TooltipDadosPixRevenda: () => import('./components/tooltipDadosPixRevenda'),
    TooltipDadosPixWeb: () => import('./components/tooltipDadosPixWeb')
    // filtroBase: () => import('./components/filtroBase')
  },
  mixins: [listagemTables],
  directives: {mask},
  data: () => ({
    historico: false,
    selected: [],
    dateFormat: 'YYYY-MM-DD',
    expanded: [],
    headers: [
      // { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Código', value: 'entity.cod' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'entity.name' },
      { align: 'start', class: 'table-header', text: 'Cod. Rev', value: 'transactions' },
      { align: 'start', class: 'table-header', text: 'Tipo PIX', value: 'type' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'pendingOperation' },
      { align: 'start', class: 'table-header', text: 'Data Solicitação', value: 'created' },
      { align: 'start', class: 'table-header', text: '', value: 'statusCor' },
      { align: 'start', class: 'table-header', text: 'Status', value: 'status' },
    ],
    headersTransaction: [
      // { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Código', value: 'entity.cod' },
      { align: 'start', class: 'table-header', text: 'Transação', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'entity.name' },
      
      { align: 'start', class: 'table-header', text: 'Tipo', value: 'type' },
      { align: 'start', class: 'table-header', text: 'Status', value: 'status' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'updated' },
      { align: 'start', width: '150', class: 'table-header', text: '', value: 'actions' },
    ],
    tipoBusca: 6,
    typeBusca: 'entities',
    itensSelect: [],
    loading: false,
    loadingBaixa: false,
    loadingExpanded: false,
    loadingTransaction: false,
    dataConfirmationPayment: {},
    dataEntityWeb: {},
    isActiveModalConfirmationPayment: false,
    dataConfirmationCancelamento: {},
    isActiveModalCancelPayment: false,
    status: ['AC', 'A', 'PR', 'PG'],
    typesTransactions: [
      { text: 'Crédito', value: 'C' },
      { text: 'Prêmios Tickets', value: 'P' },
      { text: 'Prêmios Web', value: 'R' },
    ],
    typeTransaction: '',
    exibirTransacoes: true,
    filter: {},
    rangeDate: {
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    },
    addlFilter: {},
    loadingTable: false,
    textSearch: ''
  }),
  computed: {
    ...mapGetters('despacho', ['items', 'totalItems', 'itemsTransactions', 'transacaoStatus']),
    // listPreCaixaFormat () {
    //   // let dados = this.formatExpanded(this.expanded)

    //   // dados = dados.sort((a, b) => {
    //   //   return parseInt(a.entity.cod) - parseInt(b.entity.cod)
    //   // })

    //   // return dados
    // },
    variables: () => variables,
    getHistorico () {
      let history = this.historico
      return history
    },

    item () {
      return {}
    },

    icon () {
      if (this.statusAll) return 'mdi-close-box'
      if (this.likesSomeStatus) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

    statusAll () {
      return this.transacaoStatus.length === this.status.length
    },

    likesSomeStatus () {
      return this.transacaoStatus.length > 0 && !this.statusAll
    },

    despachosOrdenados () {
      const teste = orderBy(this.items, 'order')
      return teste
    }
  },

      // this.getItems({ status: this.status, dtStart: this.rangeDate.start, dtEnd: this.rangeDate.end })
      // .finally(() => this.loadingTable = false)

  mounted () {
    this.getTransactionStatuses()
    this.handlerRequestDispatches()

    Events.$on('toolbar::update', () => {
      this.handlerRequestDispatches()
    })
  },
  watch: {
    'status' (val) {
      this.status = val,
      this.handlerRequestDispatches()
    },
    
    'typeTransaction' (val) {
      this.typeTransaction = val
      this.handlerRequestDispatches()
    },
    // 'items' () {
    //   this.expanded = this.items.map((it) => ({ id: it.id }))
    //   this.exibirTransacoes = false
    //   setTimeout(() => { this.exibirTransacoes = true }, 1000)
    // }
  },

  methods: {
    ...mapActions('despacho', {
      getItems: 'getItems',
      getTransaction: 'getTransaction',
      getTransactionStatuses: 'getTransactionStatuses',
      clearItens: 'clearItens '
    }),

    init() {},

    getTransactionExpanded (data) {
      if (data.value) {
        this.loadingExpanded = true
        this.getTransaction(data.item.id)
        .finally(() => this.loadingExpanded = false)
      }
    },
    
    // exibirBtn: (status) => !['R'].includes(status.id),
    exibirBtnCancelamento: (status) => status.id === 'B',
    getStringTypeActions (status) {
      switch (status.id) {
        case 'AC':
        case 'PG':
          return {
            texto: 'Confirmar',
            cor: this.variables.colorGreenDarken,
            fn: this.openModalConfirmationPayment
          }
        case 'C':
        case 'E':
        case 'A':
        case 'R':
          return {
            texto: 'Visualizar',
            cor: this.variables.colorGreenDarken,
            fn: this.openModalConfirmationPayment
          }
        case 'B': 
          return {
            texto: 'Cancelar Baixa',
            cor: this.variables.colorGreenDarken,
            fn: this.openModalConfirmationPayment
          }
        default:
          return {
            texto: '',
            cor: ''
          }
      }
    },

    openModalConfirmationPayment (dataPayment, entityWeb) {
      if (dataPayment) {
        this.dataConfirmationPayment = dataPayment
        this.dataEntityWeb = entityWeb
        this.isActiveModalConfirmationPayment = true
      }
    },

    openModalCancelamento (dataPayment, entityWeb) {
      this.dataConfirmationCancelamento = dataPayment
      this.dataEntityWeb = entityWeb
      this.isActiveModalCancelPayment = true
    },

    closeModalConfirmationPayment() {
      this.dataConfirmationPayment = {}
      this.dataEntityWeb = {}
      this.isActiveModalConfirmationPayment = false
    },

    closeModalRegistroConfirmado() {
      this.dataConfirmationPayment = {}
      this.isActiveModalConfirmationPayment = false,
      this.handlerRequestDispatches()
    },

    closeModalCancelPayment() {
      this.dataConfirmationCancelamento = {}
      this.isActiveModalCancelPayment = false
    },

    filtrar (e) {
      this.rangeDate = {
        start: e.start,
        end: e.end
      }

      this.handlerRequestDispatches()
    },

    toggle () {
      this.$nextTick(() => {
        if (this.statusAll) {
          this.status = ['AC', 'PG', 'B', 'PG']
        } else {
          this.status = this.transacaoStatus.map(it => it.value).slice()
        }
      })
    },

    getValue (despacho) {
      if (despacho.pendingOperation) return currency(despacho.pendingOperation.value)

      return currency(despacho.transactions[0].value)
    },

    transacoesOrdernadas (transacoes) {
      return transacoes ? orderBy(transacoes, 'updated') : []
    },

    getColorStatusTransactions (transactions) {
      const transaction = transactions[transactions.length - 1]
      switch (transaction.transactionStatusId) {
        case 'PR':
          return this.variables.colorDanger
        case 'AC':
          return this.variables.colorPixPago
        case 'PG':
          return this.variables.colorAlert
        default:
          return this.variables.colorSuccess
      }
    },

    buscarPorEntidade() {
      const textoFitro = this.textSearch.replace(/\D/g, '')
      this.addlFilter[`ee:cod_=`] = undefined
      this.addlFilter[`e:cod_=`] = undefined

      if (textoFitro.length === 11) {
        this.addlFilter[`e:cod_=`] = '55' + textoFitro
      } else if (textoFitro.length) {
        this.addlFilter[`ee:cod_=`] = this.textSearch
      }
      // this.objCampoBusca['_filter'] = { cod_ilike: `%${val.toUpperCase()}%` }
      this.handlerRequestDispatches()
    },

    handlerRequestDispatches () {
      this.loadingPag = true
      this.getItems({
        status: this.status, 
        dtStart: this.rangeDate.start, 
        dtEnd: this.rangeDate.end,
        typeTransaction: this.typeTransaction,
        _addlFilter: this.addlFilter
      })
      .catch(err => {
        errorSnackbar(err.error)
      })
      .finally(() => this.loadingPag = false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/components/tableWithVDeep';
  @import '~@/assets/styles/components/modal-cadastro';

  .cadastro-usuario::v-deep {
    .v-btn--contained{
      box-shadow: none !important;
    }
    .v-data-table thead tr th,
    .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
      color: $colorPrimary !important;
    }
  
    .box-table {
      .v-data-table-header-mobile {
        display: none;
      }
    }
  
    .fab-cadastrar-usuario {
      position: fixed;
      z-index: 1;
      bottom: 25px;
      right: 5px;
    
      @media (min-width: 599px) {
        display: none;
      }
    }
    
    table tbody tr{
      cursor: pointer;
      // border: 1px solid #ccc;
    }
    .theme--light.v-data-table tbody tr.v-data-table__selected{
      background: #14800040;
      &:hover{
        background: #14800040 !important;
      }
    }
    table tbody tr.v-data-table__expanded__row {
      background: #f1f1f1;
    }
    table tbody tr.v-data-table__expanded.v-data-table__expanded__content {
      box-shadow: none;
      td.table-expend  {
      }
      box-shadow: none;
      padding-top: 0;
      .row {
        & > div {
          border-bottom: 1px solid #ccc;
          // border-top: 1px solid #ccc;
          // display: flex;
          // justify-content: center;
          // align-items: center;
        }
        background: #d9d9D9;
        &:nth-child(2n +1) {
          background:   #e9e9e9;
        }
        transition: 0.5;
        // border-bottom: 1px solid #ccc;
        &:hover{
          background: #F1F1F1;
        } 
      }
      .row.header-line {
        &.isDeleted {
          color: #E53935
        }
    
        margin-top: 0;
        margin-left: -15px !important;
        margin-right: -15px !important ;
        &:last-child{
          margin-bottom: 0px;
        }
      }
      td > .header {
        margin-top: 0px;
        cursor: hand;
        background:white !important;
        div {
          font-weight: bold;
        }
        &:hover{
          background: white !important;
          
        }
      }
      td> .row.header-line {
        margin: 0;
      }
    }
    .wrapper-footer button {
      // // height: 36px;
      // // border-radius: 10px;
      // &:disabled{
      //   cursor: initial;
      //   border: none;
      //   background:#ccc !important;
      //   span{
      //     color: #666 !important;
      //   }
      // }
      // background: #1cb4ff !important;
      // height: 40px !important;
      // border-radius: 5px !important;
    }
  }

  .range-date::v-deep {
    .v-btn {
      height: 38px !important;
      width: 100%;
    }
  }

  .select-status::v-deep {
    .v-input__slot {
      margin-bottom: 0 !important;
    }
  }

  .v-chip {
    border-radius: 50% !important;
  }

  

</style>
